import React, { useEffect, useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import classNames from 'classnames';
import map from 'lodash/map';

import { Modal } from '../../_common/Modal/Modal';
import { Button } from '../../_common/_controls/Button/Button';
import { Input } from '../../_common/_controls/Input/Input';
import { RegisteredTest } from '../../../types/testing';
import { useTestingResultRequest } from '../../../hooks/tests-hooks';
import { TestingResults, TEST_TYPES } from '../../../constants/testing';
import { useTestingPhotoRequest } from '../../../hooks/tests-hooks';

import './ResultSelectionWindow.scss';

interface Props {
  data: RegisteredTest;
  onClose(): void;
  onUpdate(): void;
}
const formDataDefaults = {
  result: '',
  passenger_locator_no: '',
  lot_no: '',
};
const formErrorDefaults = {
  result: false,
  passenger_locator_no: false,
  lot_no: false,
};

export const ResultSelectionWindow: React.FC<Props> = (props) => {
  const { data, onClose, onUpdate } = props;
  const { type, id, lft_score, self_test } = data || {};
  const { isSaved, updateTest } = useTestingResultRequest();
  const { testPhotoResponse } = useTestingPhotoRequest(props?.data?.id || '');
  const [testPhoto, setTestPhoto] = useState('');
  const [formData, setFormData] = useState(formDataDefaults);
  const [formError, setFormError] = useState(formErrorDefaults);

  useEffect(() => {
    const initialFormData = {
      ...formDataDefaults,
      passenger_locator_no: data?.pln,
      lot_no: data?.lot_no,
    };
    setFormData(initialFormData);
  }, [data]);

  useEffect(() => {
    if (isSaved) {
      setFormData(formDataDefaults);
      setFormError(formErrorDefaults);
      onClose();
    }
  }, [isSaved]);

  useEffect(() => {
    if (testPhotoResponse && testPhotoResponse !== '') {
      const img = `data:image/png;base64,${testPhotoResponse}`;
      setTestPhoto(img);
    }
  }, [testPhotoResponse]);

  const updateTestDetails = async (data: any = {}) => {
    const success = await updateTest(id, data);

    if (success) onUpdate();
  };

  const onInputChange = (value, name) => {
    if (self_test) {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    } else {
      const data = {
        [name]: value,
      };
      updateTestDetails(data);
    }
  };

  const handleSubmit = async () => {
    map(formData, (value, key) => {
      // pln is currently not required
      if (key !== 'passenger_locator_no') {
        setFormError((prevError) => ({
          ...prevError,
          [key]: !value,
        }));
      }
    });

    if (formData.result && formData.lot_no) {
      updateTestDetails(formData);
    }
  };

  const ResultOption = ({ type }) => {
    const wrapClass = classNames(`rs-window__results__${type.toLowerCase()}`, {
      'result-toggle': true,
      [`selected-${type.toLowerCase()}`]: type === formData.result,
    });

    return (
      <button className={wrapClass} onClick={() => onInputChange(type, 'result')}>
        {type}
      </button>
    );
  };

  return (
    <Modal isOpen={!!data} onClose={onClose}>
      <div className="rs-window">
        {testPhoto && (
          <div className="rs-window__imageZoomWrapper">
            <div className="rs-window__imageCont fluid__image-container">
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: 'testing kit image',
                    isFluidWidth: true,
                    src: testPhoto,
                  },
                  largeImage: {
                    src: testPhoto,
                    width: 1000,
                    height: 1000,
                  },
                  enlargedImageContainerDimensions: {
                    width: '180%',
                    height: '100%',
                  },
                  enlargedImagePortalId: 'image-preview',
                }}
              />
            </div>
            <div className="fluid__instructions">
              <div className="fluid__instructions__preview" id="image-preview">
                <h3>preview</h3>
              </div>
            </div>
          </div>
        )}
        <div className="rs-window__test-details">
          <div className="rs-window__lft-score spacing">
            <b>Confidence score: </b> {type === TEST_TYPES.LATERAL ? `${lft_score || 0}%` : 'N/A'}
          </div>
          <div className="rs-window__type spacing">
            <b>Type:</b> {type}
          </div>
        </div>

        {/* display pln and lot no if fields if test is self test */}
        {self_test && (
          <div className="rs-window__test-details">
            <div className="rs-window__input_row">
              <Input
                title="Passenger Locator Number:"
                value={formData.passenger_locator_no}
                name="passenger_locator_no"
                onChange={(val, name) => onInputChange(val, name)}
                error={formError.passenger_locator_no}
                maxLength={20}
              />
            </div>

            <div className="rs-window__input_row">
              <Input
                title="Lot no:"
                value={formData.lot_no}
                name="lot_no"
                onChange={(val, name) => onInputChange(val, name)}
                error={formError.lot_no}
                maxLength={100}
              />
            </div>
          </div>
        )}

        <div className="rs-window__results">
          <div className="result-title">
            <b className={`result-title__${formError.result ? 'error' : 'default'}`}>Result:</b>
          </div>
          <ResultOption type={TestingResults.Negative} />
          <ResultOption type={TestingResults.Positive} />
          <ResultOption type={TestingResults.Inconclusive} />
        </div>

        {self_test && (
          <div className="rs-window__actions">
            <Button title="Submit" onClick={handleSubmit} />
          </div>
        )}
      </div>
    </Modal>
  );
};
