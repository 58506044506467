import { useDispatch, useSelector } from 'react-redux';
import { UtilsUser } from '../utils/UtilsUser';
import { API_BASE_URL, API_ENDPOINTS } from '../constants/api';
import { CommonEntityRes } from '../types/common';
import { Http } from '../services/http';
import { notification } from '../services/notifications';
import { ApplicationState } from '../store';
import { UserState } from '../store/reducers/user-reducer';
import { TestingCenterUser, TestingCenterUserUpdate, TestingUserFormEntity, TestingUserFull } from '../types/users';
import { TestingCenter } from '../types/testing';
import { actionSetTestingUsers } from '../store/actions/testing-actions';

export function useTestingCenterUserRequest() {
  const userState = useSelector<ApplicationState, UserState>(state => state.user);
  const {userData} = userState;
  const {host} = userData;

  const createUser = async (data: TestingUserFormEntity) => {
    try {
      const {mfp_id, ...permissions} = data;
      const requestData: TestingCenterUser = {
        mfp_id,
        permissions: UtilsUser.convertUserPermissions(permissions),
      };

      const url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_CENTERS}/${host.id}/${API_ENDPOINTS.USERS}`;
      const res: CommonEntityRes<TestingCenter> = await Http.post(url, requestData);

      notification.showSuccess('User is created');
    } catch (e) {
      console.log(e);
      notification.showError(e.message);
    }
  };

  return {createUser};
}

export function useTestingCenterUsersLoadRequest() {
  const dispatch = useDispatch();
  const userState = useSelector<ApplicationState, UserState>(state => state.user);
  const {userData} = userState;
  const {host} = userData;

  const loadUsers = async () => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_CENTERS}/${host.id}/${API_ENDPOINTS.USERS}`;
      const res: CommonEntityRes<TestingCenter> = await Http.get(url);

      dispatch(actionSetTestingUsers(res.data));
    } catch (e) {
      console.log(e);
    }
  };

  return {loadUsers};
}

export function useTestingCenterUserUpdateRequest() {
  const updateUser = async (data: TestingUserFormEntity) => {
    try {
      const {mfp_id, ...permissions} = data;
      const requestData: TestingCenterUserUpdate = {
        permissions: UtilsUser.convertUserPermissions(permissions),
      };

      const url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_USERS}/${mfp_id}`;
      await Http.patch(url, requestData);

      notification.showSuccess('User is updated');
    } catch (e) {
      console.log(e);
      notification.showError(e.message);
    }
  };

  return {updateUser};
}

export function useTestingCenterGetUserRequest() {
  const getUser = async (id: string) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_USERS}/${id}`;
      const res: TestingUserFull = await Http.get(url);

      return res;
    } catch (e) {
      console.log(e);
    }
  };

  return {getUser};
}
