import React from 'react';
import { Input } from '../../_common/_controls/Input/Input';
import { PageHeader } from '../../_common/PageHeader/PageHeader';
import { Button } from '../../_common/_controls/Button/Button';
import DateRangePicker from '../../_common/DateRangePicker';
import './VaccinesHeader.scss';

interface Props {
  title: string;
  searchValue: string;
  onSearch(value: string): void;
  onExport(): void;
  setDateRange: (date: any) => void;
}

export const VaccinesHeader: React.FC<Props> = (props) => {
  const { title, searchValue, onSearch, onExport, setDateRange } = props;

  const onDateSelected = (dateFrom: any, dateTo: any) => {
    if (!dateFrom || !dateTo) {
      setDateRange({ date_start: null, date_end: null });
      return;
    }
    setDateRange({ date_start: dateFrom, date_end: dateTo });
  };

  return (
    <PageHeader
      title={title}
      content={
        <div>
          <div className="vh-actions">
            <div className="RangedatePicker">
              <DateRangePicker onDateSelected={onDateSelected} />
            </div>
            <Input
              placeholder="Search Items"
              value={searchValue}
              search
              onChange={onSearch}
            />
            <div className="vh-actions__export">
              <p>Export Search to Report</p>
              <Button title="Export" onClick={onExport} />
            </div>
          </div>
        </div>
      }
    />
  );
};
