import { LocalStorage } from '../services/storage';
import { USER_TOKEN_NAMESPACE } from '../constants/namespaces';
import { AdminPermissionsNames } from '../constants/users';
import { CommonObject } from '../types/common';
import { AdminUserPermission } from '../types/users';
import { ADMIN_PERMISSIONS_MAP } from '../constants/users';

export const UtilsUser = {
  isUserTokenExists: () => {
    return !!LocalStorage.get(USER_TOKEN_NAMESPACE);
  },
  convertUserPermissions: (permissions: CommonObject): any[] => {
    return Object.keys(permissions).map((key) => {
      return {
        // @ts-ignore
        name: AdminPermissionsNames[key],
        value: permissions[key],
      };
    });
  },
  getUserPermissionsValues: (items: AdminUserPermission[]) => {
    const values: CommonObject = {};

    items.forEach((item: any) => {
      const valueName = ADMIN_PERMISSIONS_MAP[item.name];

      if (valueName) {
        values[valueName] = item.value
      }
    });

    return values;
  },
};
