import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Svg } from '../Svg/Svg';

import './NavMenuItem.scss';
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {UserState} from "../../../store/reducers/user-reducer";

interface Props {
  route?: string;
  icon: string;
  title: string;
  isActive: boolean;
  displayed?: boolean;
  onClick?: () => void;
}

export const NavMenuItem: React.FC<Props> = (props) => {
  const {route, icon, title, isActive, displayed = true, onClick} = props;
  const globalSize = useSelector<ApplicationState, UserState>(state => state.user)
  const fontSize = globalSize.fontSize === '16px' || globalSize.fontSize?.length === 0 ? '14px' : globalSize.fontSize
  const wrapClass = classNames('nav-menu-item', {'nav-menu-item--active': isActive});
  const content = (
    <>
      <Svg icon={icon} className="nav-menu-item__icon" />
      <div className="nav-menu-item__text" style={{fontSize: fontSize}}>{title}</div>
    </>
  );

  if (!displayed) return null;

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <>
      {route
        ? (
          <Link to={route} className={wrapClass}>
            {content}
          </Link>
        )
        : (
          <button type="button" className={wrapClass} onClick={handleClick}>
            {content}
          </button>
        )}
    </>
  );
};
