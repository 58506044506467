import { useDispatch } from 'react-redux';
import { API_BASE_URL, API_ENDPOINTS } from '../constants/api';
import { Http } from '../services/http';
import { CommonEntityRes, CommonObject } from '../types/common';
import { CommonUtils } from '../utils/CommonUtils';
import { useState, useEffect } from 'react';
import { notification } from '../services/notifications';
import {
  AdministeredTest,
  QrFormEntity,
  RegisteredTest,
  TestingCenter,
  TestingCenterCreateFormEntity,
  TestingDoctorCreateFormEntity,
} from '../types/testing';
import {
  actionSetAdministeredItems,
  actionSetRegisteredItems,
  actionSetTestingCenter,
  actionSetTestingDoctor,
} from '../store/actions/testing-actions';
import { TestingResults } from '../constants/testing';
import { serializeObject } from '../utils/CommonUtils';

export function useQrGenerationRequest() {
  const createQr = async (data: QrFormEntity) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_REGISTERED}`;
      await Http.post(url, data);

      alert('Code is created');
    } catch (e) {
      console.log(e);
    }
  };

  return { createQr };
}

export const fetchRegisteredItem = async (id) => {
  try {
    // TODO: Temporary use list endpoint to get details
    let url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_REGISTERED}?page=1&limit=5&search=${id}`;
    const res = await Http.get(url);

    return res;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export function useRegisteredItemsRequest() {
  const dispatch = useDispatch();

  const loadItems = async (page: number, searchParams: CommonObject, filter: CommonObject) => {
    try {
      const hasFilter = Object.keys(filter).length > 0;
      const { search } = searchParams;
      let url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_REGISTERED}?page=${page}&limit=10`;
      if (search) {
        url += `&search=${search}`;
      }

      if (hasFilter) {
        const keypairs = serializeObject(filter);
        url += keypairs ? '&' + keypairs : '';
      }
      const res = await Http.get(url);

      if (res) {
        dispatch(actionSetRegisteredItems(res));
      }
    } catch (e) {
      console.log(e);
    }
  };

  return { loadItems };
}

export function useTestingPhotoRequest(id: string) {
  const [testPhotoResponse, setResponse] = useState(null);
  const [testPhotoError, setError] = useState('');
  const [testPhotoLoading, setloading] = useState(true);

  const loadItems = async () => {
    try {
      setloading(true);
      const url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_PHOTO}/${id}`;
      const res = await Http.get(url);
      setResponse(res.image);
      setloading(false);
    } catch (err) {
      setError(err?.message);
      setResponse('');
      setloading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    loadItems();
  }, [id]);

  return { testPhotoResponse, testPhotoError, testPhotoLoading };
}

export function useAdministeredItemsRequest() {
  const dispatch = useDispatch();

  const loadItems = async (page: number, searchParams: CommonObject, filter: CommonObject) => {
    try {
      const hasFilter = Object.keys(filter).length > 0;
      const { search } = searchParams;
      let url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_ADMINISTERED}?page=${page}&limit=10`;

      if (search) {
        url += `&search=${search}`;
      }

      if (hasFilter) {
        const keypairs = serializeObject(filter);
        url += keypairs ? '&' + keypairs : '';
      }

      const res = await Http.get(url);

      dispatch(actionSetAdministeredItems(res));
    } catch (e) {
      console.log(e);
    }
  };

  return { loadItems };
}

export function useRegisteredExport() {
  const exportItemsToCsv = async (searchStr: string, itemsCount: number) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_REGISTERED}?page=${1}&limit=${itemsCount}&search=${searchStr}`;
      const foundItems: CommonEntityRes<RegisteredTest> = await Http.get(url);

      CommonUtils.exportItemsToCSV(
        foundItems.data.map(({ ftf, ftr, ftt, ...rest }) => rest),
        'Registered Test Types'
      );
    } catch (e) {
      console.log(e);
    }
  };

  return { exportItemsToCsv };
}

export function useAdministeredExport() {
  const exportItemsToCsv = async (searchStr: string, itemsCount: number) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_ADMINISTERED}?page=${1}&limit=${itemsCount}&search=${searchStr}`;
      const foundItems: CommonEntityRes<AdministeredTest> = await Http.get(url);

      CommonUtils.exportItemsToCSV(foundItems.data, 'Administered Test Types');
    } catch (e) {
      console.log(e);
    }
  };

  return { exportItemsToCsv };
}

export function useQrLoadingRequest() {
  const [qr, setQr] = useState('');

  const loadQr = async (link: string) => {
    try {
      const fixedLink = link.replace('/vaccine', '');
      const url = `${API_BASE_URL}${fixedLink}`;
      const res = await Http.get(url, { 'Content-type': 'image/png' });
      const resBuffer = await res.blob();

      const imageUrl = URL.createObjectURL(resBuffer);
      const img: any = document.createElement('img');
      img.src = url;
      // in case you don't need the blob anymore
      img.onload = () => URL.revokeObjectURL(url);

      setQr(imageUrl);
    } catch (e) {
      console.log(e);
    }
  };

  return { loadQr, qr };
}

export function useTestingCenterLoadingRequest() {
  const dispatch = useDispatch();

  const loadCenter = async (id: string) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_CENTERS}/${id}`;
      const res: TestingCenter = await Http.get(url);

      const doctorUrl = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_DOCTOR.replace('{id}', res.id)}`;
      const doctorRes = await Http.get(doctorUrl);
      const { id: doctorId, created_at, updated_at, centre_id, ...doctorData } = doctorRes;

      dispatch(actionSetTestingCenter(res));
      dispatch(actionSetTestingDoctor(doctorData));
    } catch (e) {
      console.log(e);
    }
  };

  return { loadCenter };
}

export function useTestingCenterUpdateRequest() {
  const dispatch = useDispatch();

  const updateCenter = async (id: string, data: TestingCenterCreateFormEntity) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_CENTERS}/${id}`;
      const res: TestingCenter = await Http.put(url, data);

      notification.showSuccess('Center Is Updated');
    } catch (e) {
      console.log(e);
      notification.showError(e.message);
    }
  };

  return { updateCenter };
}

export function useTestingDoctorCreate() {
  const [isSaved, setIsSaved] = useState(false);

  const uploadSignature = async (signUrl: string, file: File) => {
    const url = `${API_BASE_URL}/${signUrl}`;
    const data = new FormData();
    data.append('file', file, file.name);

    await Http.postFile(url, data);
  };

  const createDoctor = async (data: TestingDoctorCreateFormEntity, centerId: string) => {
    try {
      setIsSaved(false);
      const { signature, ...doctorData } = data;
      const handledPath = API_ENDPOINTS.TESTING_DOCTOR.replace('{id}', centerId);
      const handledSignPath = API_ENDPOINTS.TESTING_DOCTOR_SIGNATURE.replace('{id}', centerId);
      const url = `${API_BASE_URL}/${handledPath}`;
      await Http.put(url, doctorData);
      if (signature && typeof signature !== 'boolean') {
        await uploadSignature(handledSignPath, signature);
      }

      setIsSaved(true);
      notification.showSuccess('Doctor Is Updated');
      return true;
    } catch (e) {
      console.log(e);
      setIsSaved(false);
      notification.showError(e.message);
      return false;
    }
  };

  return { createDoctor, isSaved };
}

export function useTestingResultRequest() {
  const [isSaved, setIsSaved] = useState(false);

  const updateTest = async (testId: string, data: {}) => {
    try {
      setIsSaved(false);
      const url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_TEST}/${testId}`;
      await Http.patch(url, data);

      setIsSaved(true);
      notification.showSuccess('Result Is Set');
      return true;
    } catch (e) {
      console.log(e);
      setIsSaved(false);
      notification.showError(e.message);
      return false;
    }
  };

  return { isSaved, updateTest };
}
