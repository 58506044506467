import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '../../_common/Modal/Modal';
import { ApplicationState } from '../../../store';
import { UserState } from '../../../store/reducers/user-reducer';

import './UserQrWindow.scss';

interface Props {
  isOpened: boolean;
  onClose(): void;
}

export const UserQrWindow: React.FC<Props> = (props) => {
  const {isOpened, onClose} = props;
  const userState = useSelector<ApplicationState, UserState>(state => state.user);

  return (
    <Modal isOpen={isOpened} onClose={onClose}>
      <div className="user-qr">
        <img src={userState.qr} alt="" />
      </div>
    </Modal>
  );
};
