import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UsersHeader } from '../../../../components/users/UsersHeader/UsersHeader';
import { UserEditForm } from '../../../../components/users/UserEditForm/UserEditForm';
import { ApplicationState } from '../../../../store';
import { UserState } from '../../../../store/reducers/user-reducer';
import { useTestingCenterUsersLoadRequest, useTestingCenterUserUpdateRequest } from '../../../../hooks/users-hooks';
import { TestingUserFormEntity } from '../../../../types/users';

export const UserEditPage = (): JSX.Element => {
  const userState = useSelector<ApplicationState, UserState>((state) => state.user);
  const { userData } = userState;
  const userId = userData.id;
  const { loadUsers } = useTestingCenterUsersLoadRequest();
  const { updateUser } = useTestingCenterUserUpdateRequest();

  useEffect(() => {
    if (userId) {
      loadUsers();
    }
  }, [userId]);

  const handleUserUpdate = (data: TestingUserFormEntity) => {
    updateUser(data);
  };

  return (
    <div>
      <UsersHeader />
      <div className="page-content">
        <UserEditForm onSubmit={handleUserUpdate} />
      </div>
    </div>
  );
};
