import { SignInEntity } from '../types/authorization';
import { User } from '../types/user';
import { TestingCenter, TestingDoctorCreateFormEntity } from '../types/testing';
import { TestingUserFormEntity } from '../types/users';
import { TestingBroadcastFormEntity } from '../types/broadcast';

export const signInDefaults: SignInEntity = {
  login: '',
  password: '',
};

export const userDefaults: User = {
  id: '',
  name: '',
  created_at: '',
  updated_at: '',
  phone: '',
  mfp_id: '',
  host: null,
  permissions: [],
  role: {
    level: 0,
    created_at: '',
    id: '',
    name: '',
    updated_at: '',
  },
};

export const testingCenterDefault: TestingCenter = {
  address1: '',
  address2: '',
  city: '',
  created_at: '',
  email: '',
  id: '',
  key_contact: '',
  name: '',
  phone: '',
  postcode: '',
  updated_at: '',
  is_lft_ai_used: false,
  is_pln_required: false,
};

export const testingFormEntityDefaults: TestingUserFormEntity = {
  mfp_id: '',
  BroadcastMessage: false,
  SubUserManagement: false,
  RegisteredTestTypes: false,
  AdministeredTestTypes: false,
  CentreDetails: false,
};

export const testingBroadcastFormEntityDefaults: TestingBroadcastFormEntity = {
  centre_id: '',
  all_centres: false,
  lot_no: '',
  type: '',
  date: '',
  identity_code: '',
  message: '',
};

export const testingDoctorCreateDefaults: TestingDoctorCreateFormEntity = {
  address1: '',
  address2: '',
  city: '',
  email: '',
  key_contact: '',
  name: '',
  phone: '',
  postcode: '',
  signature: undefined,
};
