// @ts-nocheck
import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import './DateRange.scss';

interface IProps {
  onDateSelected: (start: any, end: any) => void;
}

interface RemoveProps {
  source: string;
}

const DateRangePicker = ({ onDateSelected }: IProps) => {
  const [dateFrom, setDateFrom] = useState(undefined);
  const [dateTo, setDateTo] = useState(undefined);
  const inputTo = useRef();

  const showFromMonth = () => {
    if (!dateFrom) {
      return;
    }
    // if (moment(dateTo).diff(moment(dateFrom), 'months') < 2) {
    //   inputTo.current.getDayPicker().showMonth(dateFrom);
    // }
  };

  const handleFromChange = (from: any) => {
    setDateFrom(from);
  };

  const handleToChange = (to: any) => {
    setDateTo(to);
  };

  useEffect(() => {
    showFromMonth();
  }, [dateTo]);

  useEffect(() => {
    if (dateFrom && dateTo) {
      onDateSelected(moment(dateFrom).format('YYYY-MM-DD'), moment(dateTo).format('YYYY-MM-DD'));
    } else {
      onDateSelected(null, null);
    }
  }, [dateFrom, dateTo]);

  const RemoveDate = ({ source }: RemoveProps) => {
    const handleRemoveDate = () => {
      if (source === 'from') {
        setDateFrom(null);
      } else if (source === 'to') {
        setDateTo(null);
      }
    };

    return <span onClick={handleRemoveDate} className="remove__btn" />;
  };

  const modifiers = { start: dateFrom, end: dateTo };
  return (
    <div className="InputFromTo">
      <span className="date__picker__container">
        <DayPickerInput
          value={dateFrom}
          placeholder="Start date"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [dateFrom, { from: dateFrom, to: dateTo }],
            disabledDays: { after: dateTo },
            toMonth: dateTo,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => inputTo.current.getInput().focus(),
          }}
          onDayChange={handleFromChange}
        />
        {'  '}
        {dateFrom && <RemoveDate source="from" />}
      </span>
      {'  '} — {'  '}
      <span className="date__picker__container">
        <DayPickerInput
          ref={inputTo}
          value={dateTo}
          placeholder="End date"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [dateFrom, { from: dateFrom, to: dateTo }],
            disabledDays: { before: dateFrom },
            modifiers,
            month: dateFrom,
            fromMonth: dateFrom,
            numberOfMonths: 2,
          }}
          onDayChange={handleToChange}
        />
        {dateTo && <RemoveDate source="to" />}
      </span>
    </div>
  );
};

export default DateRangePicker;
