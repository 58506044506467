import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import './RadioGroup.scss';

interface RadioOption {
  title: any;
  value: any;
}

interface Props {
  title?: string;
  options: RadioOption[];
  value: any;
  onSelect: (value: any, name: string) => void;
  name: string;
}

export const RadioGroup: React.FC<Props> = ({
  title,
  options,
  value = '',
  onSelect,
  name,
}) => {
  const [checked, setChecked] = useState(value);
  const wrapperClass = classNames('radio-group');

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const onRadioSelect = (selected: any) => {
    setChecked(selected);
    onSelect(selected, name);
  };

  const renderOptions = () => {
    return options.map((option) => (
      <label className="radio-item" key={option.title}>
        {option.title}
        <input
          type="radio"
          id={option.title}
          onChange={() => onRadioSelect(option.value)}
          checked={checked === option.value}
          name="radio"
        />
        <span className="checkmark" />
      </label>
    ));
  };

  return (
    <div className={wrapperClass}>
      <p className="radio-group__title">{title}</p>
      <div className="radio-group__options">{renderOptions()}</div>
    </div>
  );
};
