import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePermissionsRedirect, useUserDataRequest } from '../../hooks/user-hooks';
import { Header } from '../Header/Header';
import { Sidebar } from '../Sidebar/Sidebar';
import './Layout.scss';

export const Layout: React.FC = (props) => {
  const { children } = props;
  const { pathname } = useLocation();
  const { loadUser } = useUserDataRequest();
  const isUserExists = pathname.indexOf('sign-in') === -1;

  usePermissionsRedirect();

  useEffect(() => {
    loadUser();
  }, []);
  const wrapClass = classNames('layout', {
    'layout--nonauth': isUserExists,
  });

  return (
    <div className={wrapClass}>
      {isUserExists ? (
        <div className="auth-layout">
          <Header />
          <div className="auth-layout__wrap">
            <div className="auth-layout__sidebar">
              <Sidebar />
            </div>
            <div className="auth-layout__content">{children}</div>
          </div>
        </div>
      ) : (
        <div className="nonauth-layout">
          <div>
            <div className="nonauth-layout__logo" />
            <div className="nonauth-layout__wrap">{children}</div>
          </div>
        </div>
      )}
    </div>
  );
};
