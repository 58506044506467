import React from 'react';
import classNames from 'classnames';

import './FormCard.scss';

interface Props {
  title?: string;
  className?: string;
  rightHeadContent?: React.ReactNode;
}

export const FormCard: React.FC<Props> = (props) => {
  const {children, className = '', title, rightHeadContent} = props;
  const wrapClass = classNames('form-card', {
    [className]: !!className,
  });

  return (
    <div className={wrapClass}>
      <div className="form-card__head">
        <div className="form-card__title title h3">{title}</div>
        {rightHeadContent}
      </div>
      {children}
    </div>
  );
};
