import React, { useState } from 'react';
import { Form } from '../../_common/Form/Form';
import { SignInEntity } from '../../../types/authorization';
import { Input } from '../../_common/_controls/Input/Input';
import { signInDefaults } from '../../../data/defaults';
import { Button } from '../../_common/_controls/Button/Button';

import './SignInForm.scss';

interface Props {
  onSubmit(data: SignInEntity): void;
}

export const SignInForm: React.FC<Props> = (props) => {
  const {onSubmit} = props;
  const [formData, setFormData] = useState<SignInEntity>(signInDefaults);
  const {login, password} = formData;
  const buttonIsDisabled = !login || !password;

  const handleInputChange = (value: string, name: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Form className="auth-form" onSubmit={handleFormSubmit}>
      <Input
        value={login}
        name="login"
        placeholder="Email address / phone number..."
        onChange={handleInputChange}
      />
      <Input
        value={password}
        name="password"
        placeholder="Password"
        password
        onChange={handleInputChange}
      />
      <Button title="Continue" htmlType="submit" disabled={buttonIsDisabled} />
    </Form>
  );
};
