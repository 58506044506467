export const USER_ACTION_TYPES = {
  SET_USER: 'SET_USER',
  SET_USER_LOGIN_STATUS: 'SET_USER_LOGIN_STATUS',
  SET_USER_QR: 'SET_USER_QR',
  SET_FONT_SIZE: 'SET_FONT_SIZE',
  SET_FCM_INSTANCE: 'SET_FCM_INSTANCE',
};

export const TESTING_ACTION_TYPES = {
  SET_REGISTERED_ITEMS: 'SET_REGISTERED_ITEMS',
  SET_ADMINISTERED_ITEMS: 'SET_ADMINISTERED_ITEMS',
  SET_TESTING_USERS: 'SET_TESTING_USERS',
  SET_TESTING_CENTER: 'SET_TESTING_CENTER',
  SET_TESTING_DOCTOR: 'SET_TESTING_DOCTOR',
  SET_SELECTED_TEST: 'SET_SELECTED_TEST',
};

export const BROADCAST_ACTION_TYPES = {
  SET_BROADCAST_TESTING_TYPES: 'SET_BROADCAST_TESTING_TYPES',
  SET_BROADCAST_TESTING_LOTS: 'SET_BROADCAST_TESTING_LOTS',
  SET_BROADCAST_TESTING_CODES: 'SET_BROADCAST_TESTING_CODES',
  SET_BROADCAST_TESTING_DATES: 'SET_BROADCAST_TESTING_DATES',
};
