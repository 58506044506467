import React from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import './Svg.scss';

import Safe from '../../../assets/images/svg/safe.svg';
import ChevronRight from '../../../assets/images/svg/chevron-right.svg';
import ChevronLeft from '../../../assets/images/svg/chevron-left.svg';
import Search from '../../../assets/images/svg/search.svg';
import Shield from '../../../assets/images/svg/shield.svg';
import Comment from '../../../assets/images/svg/comment.svg';
import Info from '../../../assets/images/svg/information.svg';
import User from '../../../assets/images/svg/user.svg';
import Logout from '../../../assets/images/svg/logout.svg';
import Verified from '../../../assets/images/svg/verified.svg';
import Cancel from '../../../assets/images/svg/cancel.svg';
import Font from '../../../assets/images/svg/font.svg';
import Bell from '../../../assets/images/svg/bell.svg';
import BellFill from '../../../assets/images/svg/bell-fill.svg';
import Notification from '../../../assets/images/svg/notification.svg';
import NotificationActive from '../../../assets/images/svg/notification-active.svg';
import NotificationWhite from '../../../assets/images/svg/notification-white.svg';
import ScanQR from '../../../assets/images/svg/scan-qr.svg';

// test results
import TestPositive from '../../../assets/images/svg/test-positive.svg';
import TestNegative from '../../../assets/images/svg/test-negative.svg';
import TestInconclusive from '../../../assets/images/svg/test-inconclusive.svg';

export const SVG_ICONS = {
  SAFE: Safe,
  CHEVRON_RIGHT: ChevronRight,
  CHEVRON_LEFT: ChevronLeft,
  SEARCH: Search,
  SHIELD: Shield,
  COMMENT: Comment,
  INFO: Info,
  USER: User,
  LOGOUT: Logout,
  VERIFIED: Verified,
  CANCEL: Cancel,
  FONT: Font,
  TEST_POSITIVE: TestPositive,
  TEST_NEGATIVE: TestNegative,
  TEST_INCONCLUSIVE: TestInconclusive,
  BELL: Bell,
  BELL_FILL: BellFill,
  NOTIFICATION: Notification,
  NOTIFICATION_ACTIVE: NotificationActive,
  NOTIFICATION_WHITE: NotificationWhite,
  SCAN_QR: ScanQR,
};

interface Props {
  icon: string;
  className?: string;
  size?: number;
}

export const Svg: React.FC<Props> = (props) => {
  const { icon, className = '', size = 20 } = props;
  const wrapClass = classNames('svg', { [className]: !!className });
  const style = {
    width: size,
    height: size,
  };

  return <ReactSVG src={icon} wrapper="span" style={style} className={wrapClass} />;
};
