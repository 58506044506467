import { BROADCAST_ACTION_TYPES } from '../actions-types';
import {
  TestingDate,
  TestingIdentityCode,
  TestingLotNo,
  TestingType
} from '../../types/broadcast';
import { CustomAction } from '../index';

export const actionSetBroadcastTestingTypes = (data: TestingType[]): CustomAction => {
  return {
    type: BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_TYPES,
    data,
  };
};

export const actionSetBroadcastTestingLots = (data: TestingLotNo[]): CustomAction => {
  return {
    type: BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_LOTS,
    data,
  };
};

export const actionSetBroadcastTestingCodes = (data: TestingIdentityCode[]): CustomAction => {
  return {
    type: BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_CODES,
    data,
  };
};

export const actionSetBroadcastTestingDates = (data: TestingDate[]): CustomAction => {
  return {
    type: BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_DATES,
    data,
  };
};