import { CustomAction } from '../index';
import { USER_ACTION_TYPES } from '../actions-types';
import { User } from '../../types/user';

export const actionSetUser = (data: User): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER,
    data,
  };
};
export const actionSetFontSize = (data: string): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_FONT_SIZE,
    data,
  };
};

export const actionSetUserQr = (data: string): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER_QR,
    data,
  };
};

export const actionSetLoginStatusUser = (status: boolean): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER_LOGIN_STATUS,
    data: status,
  };
};

export const actionSetFcmInstance = (data: any): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_FCM_INSTANCE,
    data,
  };
};
