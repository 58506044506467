import React from 'react';

interface Props {
  value: number;
  size: number;
  isActive: boolean;
  onClick(value: number): void;
}

export const FontSizeChangerBtn: React.FC<Props> = (props) => {
  const { value, size, isActive, onClick } = props;
  const styles = {
    fontSize: size,
    textDecoration: isActive ? 'underline' : 'none',
  };

  const handleClick = () => {
    onClick(value);
  };

  return (
    <button type="button" style={styles} className="btn-empty" onClick={handleClick}>A</button>
  );
};
