import React from 'react';
import { PageHeader } from '../../../components/_common/PageHeader/PageHeader';
import { BroadcastForm } from '../../../components/broadcast/BroadcastForm/BroadcastForm';
import { useBroadcastTestingMessageRequest } from '../../../hooks/broadcast-hooks';
import { TestingBroadcastFormEntity } from '../../../types/broadcast';

export const BroadcastPage = (): JSX.Element => {
  const { createMessage } = useBroadcastTestingMessageRequest();

  const handleMessageCreate = (data: TestingBroadcastFormEntity) => {
    createMessage(data);
  };

  return (
    <div>
      <PageHeader title="Broadcast Message" />
      <div className="page-content">
        <BroadcastForm onSubmit={handleMessageCreate} />
      </div>
    </div>
  );
};
