import React from 'react';
import classNames from 'classnames';

import './TextArea.scss';

interface Props {
  value: string;
  name?: string;
  title?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string, name: string) => void;
}

export const TextArea: React.FC<Props> = (props) => {
  const {title, value = '', name = '', placeholder, disabled, onChange} = props;
  const wrapperClass = classNames('textarea', {
    'textarea--disabled': disabled,
  });

  const handleChange = (e: React.ChangeEvent<any>) => {
    const input = e.target;

    if (onChange) {
      onChange(input.value, name);
    }
  };

  return (
    <div className={wrapperClass}>
      {title && <div className="textarea__title">{title}</div>}
      <textarea
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
      />
    </div>
  );
};
