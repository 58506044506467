import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from './configs/routes';
import { Layout } from './components/Layout/Layout';
import { auth, notAuth } from './services/auth';
import { SignInPage } from './pages/nonauth/SignInPage/SignInPage';
import { BroadcastPage } from './pages/auth/BroadcastPage/BroadcastPage';
import { CentreDetailsPage } from './pages/auth/CentreDetailsPage/CentreDetailsPage';
import { UserCreatePage } from './pages/auth/user/UserCreatePage/UserCreatePage';
import { UserEditPage } from './pages/auth/user/UserEditPage/UserEditPage';
import { AdministeredTypesPage } from './pages/auth/AdministeredTypesPage/AdministeredTypesPage';
import { RegisteredTypesPage } from './pages/auth/RegisteredTypesPage/RegisteredTypesPage';
import { PlaceholderPage } from './pages/auth/PlaceholderPage/PlaceholderPage';

const AppRouter = () => (
  <Router>
    <Layout>
      <Switch>
        <Route exact path={ROUTES.SIGN_IN} component={notAuth(SignInPage)} />
        <Route exact path={ROUTES.REGISTERED_TYPES} component={auth(RegisteredTypesPage)} />
        <Route exact path={ROUTES.ADMINISTERED_TYPES} component={auth(AdministeredTypesPage)} />
        <Route exact path={ROUTES.BROADCAST} component={auth(BroadcastPage)} />
        <Route exact path={ROUTES.CENTRE} component={auth(CentreDetailsPage)} />
        <Route exact path={ROUTES.USER_CREATE} component={auth(UserCreatePage)} />
        <Route exact path={ROUTES.USER_EDIT} component={auth(UserEditPage)} />
        <Route exact path={ROUTES.PLACEHOLDER} component={auth(PlaceholderPage)} />
        <Redirect exact from={ROUTES.ROOT} to={ROUTES.REGISTERED_TYPES} />
      </Switch>
    </Layout>
  </Router>
);

export default AppRouter;
