import React from 'react';
import { UsersHeader } from '../../../../components/users/UsersHeader/UsersHeader';
import { UserCreateForm } from '../../../../components/users/UserCreateForm/UserCreateForm';
import { useTestingCenterUserRequest } from '../../../../hooks/users-hooks';
import { TestingUserFormEntity } from '../../../../types/users';

export const UserCreatePage = (): JSX.Element => {
  const { createUser } = useTestingCenterUserRequest();

  const handleUserCreate = (data: TestingUserFormEntity) => {
    createUser(data);
  };

  return (
    <div>
      <UsersHeader />
      <div className="page-content">
        <UserCreateForm onSubmit={handleUserCreate} />
      </div>
    </div>
  );
};
