import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import logo from '../../assets/images/svg/mfp-favicon-host-web-app.svg';
import { SVG_ICONS } from '../_common/Svg/Svg';
import { NavMenuItem } from '../_common/NavMenuItem/NavMenuItem';
import { useUserLogin } from '../../hooks/user-hooks';
import { UserQrWindow } from '../testing/UserQrWindow/UserQrWindow';
import { ApplicationState } from '../../store';
import { UserState } from '../../store/reducers/user-reducer';
import { FontSizeChanger } from '../FontSizeChanger/FontSizeChanger';

import './Header.scss';
import { Notifications } from '../Notifications/Notifications';
import ScannerQR from '../ScannerQR/ScannerQR';

export const Header: React.FC = () => {
  const { logout } = useUserLogin();
  const userState = useSelector<ApplicationState, UserState>((state) => state.user);
  const [showQr, setShowQr] = useState(false);

  const handleLogout = () => {
    logout();
  };

  const toggleQrVisibility = () => {
    setShowQr(!showQr);
  };

  return (
    <>
      <header className="header">
        <div className="header__content">
          <div className="header__logo">
            <div className="header__logo-icon">
              <img src={logo} alt="logo" />
            </div>
            <div className="header__logo-text">Match Fit Pass - Covid-19 Testing</div>
          </div>
          <div className="header__actions">
            <Notifications />
            <ScannerQR />
            <FontSizeChanger />
            {userState.qr && (
              <button type="button" className="header__sqrc-btn" onClick={toggleQrVisibility}>
                <img src={userState.qr} alt="" />
              </button>
            )}
            <NavMenuItem icon={SVG_ICONS.LOGOUT} title="Logout" isActive={false} onClick={handleLogout} />
          </div>
        </div>
      </header>
      <UserQrWindow isOpened={showQr} onClose={toggleQrVisibility} />
    </>
  );
};
