import React from 'react';
import { PageHeader } from '../../_common/PageHeader/PageHeader';
import { ROUTES } from '../../../configs/routes';

interface Props {
}

export const UsersHeader: React.FC<Props> = (props) => {
  const {} = props;

  const pageSections = [
    {
      route: ROUTES.USER_CREATE,
      name: 'Create a New User',
    },
    {
      route: ROUTES.USER_EDIT,
      name: 'Edit an Active User',
    },
  ];

  return (
    <PageHeader title="User Account Management" sections={pageSections} />
  )
};
