import React from 'react';
import Select from 'react-select';

import './SelectMenu.scss';

interface Props {
  value: string;
  options: any[];
  title?: string;
  name?: string;
  labelKey?: string;
  valueKey?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange(value: string, name?: string): void;
}

export const SelectMenu: React.FC<Props> = (props) => {
  const {title, value, name, placeholder, options, disabled, labelKey = 'label', valueKey = 'value', onChange} = props;
  const selectedOption = options.find((option) => value === option[valueKey]) || '';

  const handleChange = (option: any) => {
    onChange(option[valueKey], name);
  };

  return (
    <div className="select-menu">
      {title && <div className="select-menu__title">{title}</div>}
      <Select
        className="select-menu__field"
        classNamePrefix="select-menu"
        value={selectedOption}
        placeholder={placeholder}
        onChange={handleChange}
        options={options}
        isDisabled={disabled}
        getOptionLabel={(option) => option[labelKey]}
        getOptionValue={(option) => option[valueKey]}
      />
    </div>
  );
};
