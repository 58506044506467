import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PageHeader } from '../../../components/_common/PageHeader/PageHeader';
import { TestingCenterForm } from '../../../components/centre/TesingCenterForm/TestingCenterForm';
import { TestingDoctorForm } from '../../../components/centre/TestingDoctorForm/TestingDoctorForm';
import { ApplicationState } from '../../../store';
import { UserState } from '../../../store/reducers/user-reducer';
import { TestingState } from '../../../store/reducers/testing-reducer';
import {
  useTestingCenterLoadingRequest,
  useTestingCenterUpdateRequest,
  useTestingDoctorCreate,
} from '../../../hooks/tests-hooks';
import { TestingCenterCreateFormEntity, TestingDoctorCreateFormEntity } from '../../../types/testing';

export const CentreDetailsPage = (): JSX.Element => {
  const userState = useSelector<ApplicationState, UserState>((state) => state.user);
  const testingState = useSelector<ApplicationState, TestingState>((state) => state.testing);
  const { loadCenter } = useTestingCenterLoadingRequest();
  const { updateCenter } = useTestingCenterUpdateRequest();
  const { createDoctor } = useTestingDoctorCreate();
  const { userData } = userState;
  const { center, doctor } = testingState;
  const userId = userData?.id;
  const centerId = userData?.host?.id;

  useEffect(() => {
    if (userId) {
      loadCenter(centerId);
    }
  }, [userId]);

  const handleCentreUpdate = (data: TestingCenterCreateFormEntity) => {
    updateCenter(userData.host.id, data);
  };

  const handleDoctorSave = (data: TestingDoctorCreateFormEntity) => {
    if (centerId) {
      createDoctor(data, centerId);
    }
  };

  return (
    <div>
      <PageHeader title="Test Centre Details" />
      <div className="page-content">
        <TestingCenterForm data={center} onSubmit={handleCentreUpdate} />
        <TestingDoctorForm data={doctor} onSubmit={handleDoctorSave} />
      </div>
    </div>
  );
};
