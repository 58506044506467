import React, { useState } from 'react';
import classNames from 'classnames';
import { InputTypes } from '../../../../constants/common';
import { Svg, SVG_ICONS } from '../../Svg/Svg';

import './Input.scss';

interface Props {
  onBlur?: any;
  error?: boolean;
  value: string;
  name?: string;
  title?: string;
  placeholder?: string;
  reset?: boolean;
  password?: boolean;
  disabled?: boolean;
  search?: boolean;
  onChange?: (value: string, name: string) => void;
  maxLength?: number;
}

export const Input: React.FC<Props> = (props) => {
  const {
    title,
    value = '',
    name = '',
    placeholder,
    reset,
    password,
    disabled,
    search,
    onChange,
    onBlur,
    error,
    maxLength,
  } = props;
  const showResetBtn = reset && !!value.length;
  const type = password ? InputTypes.Password : InputTypes.Text;
  const [localType, setLocalType] = useState(type);
  const wrapperClass = classNames('input', {
    'input--reset': reset,
    'input--disabled': disabled,
    'input--search': search,
  });
  const passwordBtnClass = classNames('input__password-btn', {
    'input__password-btn--active': localType === InputTypes.Text,
  });

  const handleChange = (e: React.ChangeEvent<any>) => {
    const input = e.target;

    if (onChange) {
      onChange(input.value, name);
    }
  };

  const handleReset = () => {
    if (onChange) {
      onChange('', name);
    }
  };

  const togglePasswordShow = () => {
    if (localType === 'password') {
      setLocalType(InputTypes.Text);
    } else {
      setLocalType(InputTypes.Password);
    }
  };
  const checkError = error ? { color: 'red' } : { color: 'black' };
  return (
    <label className={wrapperClass}>
      {title && (
        <div style={checkError} className="input__title">
          {title}
        </div>
      )}
      {search && <Svg icon={SVG_ICONS.SEARCH} className="input__icon-search" />}
      <input
        onBlur={
          onBlur
            ? (e) => {
                onBlur(e.target.name);
              }
            : () => {}
        }
        type={localType}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        maxLength={maxLength}
      />
      {showResetBtn && (
        <button
          className="input__reset-btn"
          type="button"
          onClick={handleReset}
        />
      )}
      {password && (
        <button
          className={passwordBtnClass}
          type="button"
          onClick={togglePasswordShow}
        />
      )}
    </label>
  );
};
