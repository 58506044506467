import React from 'react';
import classNames from 'classnames';

import './Button.scss';

interface Props {
  title: string;
  htmlType?: 'button' | 'submit' | 'reset';
  type?: 'primary' | 'primary-2' | 'secondary' | 'empty' | 'purple' | 'outline' | 'danger';
  variant?: 'solid' | 'outlined';
  disabled?: boolean;
  onClick?: () => void;
}

export const Button = (props: Props): JSX.Element => {
  const { title, htmlType = 'button', type = 'primary', disabled, onClick, variant = 'solid' } = props;
  const wrapClass = classNames('button', {
    [`button--${type}`]: !!type,
    'button--disabled': disabled,
    'button--outlined': variant === 'outlined',
    [`button--outlined__${type}`]: variant === 'outlined',
  });

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button className={wrapClass} type={htmlType} disabled={disabled} onClick={handleClick}>
      {title}
    </button>
  );
};
