export enum TestingResults {
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
  Inconclusive = 'INCONCLUSIVE',
  Waiting = 'WAITING',
  Verifying = 'VERIFYING',
  Ready_to_scan = 'READY TO SCAN',
}

export enum TEST_TYPES {
  LATERAL = 'Lateral Flow',
  LAMP = 'Loop Mediated Isothermal Amplification',
  PCR = 'Polymerase Chain Reaction',
}