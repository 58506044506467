import React, { useState } from 'react';
import { Svg, SVG_ICONS } from '../_common/Svg/Svg';
import ScannerModal from './ScannerModal';

const ScannerQR = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="scanner-qr">
        <button className="scanner-qr-button" onClick={() => setModalOpen(true)}>
          <Svg icon={SVG_ICONS.SCAN_QR} size={30} className="bell" />
        </button>
      </div>
      <ScannerModal isOpen={modalOpen} setOpen={() => setModalOpen(false)} />
    </>
  );
};

export default ScannerQR;
