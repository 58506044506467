import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { UtilsUser } from '../utils/UtilsUser';
import { ROUTES } from '../configs/routes';

const locationHelper = locationHelperBuilder({});

export const auth = connectedRouterRedirect({
  allowRedirectBack: true,
  authenticatedSelector: () => UtilsUser.isUserTokenExists(),
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || ROUTES.SIGN_IN;
  },
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const notAuth = connectedRouterRedirect({
  allowRedirectBack: false,
  authenticatedSelector: () => !UtilsUser.isUserTokenExists(),
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || ROUTES.REGISTERED_TYPES;
  },
  wrapperDisplayName: 'UserIsNotAuthenticated',
});