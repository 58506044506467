import { ExportToCsv } from 'export-to-csv';
import moment, { Moment } from 'moment';

export const CommonUtils = {
  exportItemsToCSV: (data: any[], title: string) => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: title,
      useTextFile: false,
      useBom: true,
      filename: title.toLowerCase(),
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  },
};

export const serializeObject = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p) && obj[p] !== null && obj[p] !== undefined) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

export const getNotificationDateLabel = (date: Moment) => {
  // set date label (e.g. February 16th, 2022)
  let dateLabel = date.format('MMMM Do, YYYY');

  // handle yesterday/today
  if (date.isSame(moment(), 'date')) dateLabel = 'Today';
  else if (date.isSame(moment().subtract(1, 'days'), 'date')) dateLabel = 'Yesterday';

  return dateLabel;
};
