import _ from 'lodash';
import React, { useEffect } from 'react';
import { Bars } from 'react-loader-spinner';
import { QRCodeSVG } from 'qrcode.react';
import { useSelector } from 'react-redux';
import { useScannerQR, useScannerQRFetch } from '../../hooks/scanner-hooks';
import { ApplicationState } from '../../store';
import { UserState } from '../../store/reducers/user-reducer';
import Divider from '../_common/Divider/Divider';
import Logo from '../_common/Logo/Logo';
import { Modal } from '../_common/Modal/Modal';
import { Button } from '../_common/_controls/Button/Button';
import './ScannerModal.scss';

type Props = {
  isOpen: boolean;
  setOpen: (val: boolean) => void;
};
const ScannerModal: React.FC<Props> = (props) => {
  const { isOpen, setOpen } = props;

  const userState = useSelector<ApplicationState, UserState>((state) => state.user);
  const { isFetching, isRefetching, refetch } = useScannerQRFetch(userState.userData);
  const { qr } = useScannerQR();

  useEffect(() => {
    if (_.isEmpty(qr) && !isFetching) refetch();
  }, [qr, isFetching]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="scanner-modal">
        <div className="container">
          <Logo style={{ width: 192 }} />
          <div className="scanner-modal__label">
            <div className="h3">Host QR Code</div>
            <div>Please scan QR code</div>
          </div>
          <div className="qr">
            {_.isEmpty(qr) && isRefetching && isFetching ? (
              <Bars color="#463da3" ariaLabel="loading" />
            ) : (
              qr && <QRCodeSVG value={qr} size={200} />
            )}
          </div>
        </div>
        <Divider />
        <div className="footer">
          <Button title="Close" type="outline" onClick={() => setOpen(false)} />
        </div>
      </div>
    </Modal>
  );
};

export default ScannerModal;
