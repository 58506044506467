import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from '../../_common/Form/Form';
import { SelectMenu } from '../../_common/_controls/SelectMenu/SelectMenu';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { TextArea } from '../../_common/_controls/TextArea/TextArea';
import { Button } from '../../_common/_controls/Button/Button';
import {
  useBroadcastTestingCodesRequest,
  useBroadcastTestingDatesRequest,
  useBroadcastTestingLotsRequest,
  useBroadcastTestingTypesRequest
} from '../../../hooks/broadcast-hooks';
import { ApplicationState } from '../../../store';
import { BroadcastState } from '../../../store/reducers/broadcast-reducer';
import { TestingBroadcastFormEntity } from '../../../types/broadcast';
import { testingBroadcastFormEntityDefaults } from '../../../data/defaults';
import { EditCard } from '../../_common/EditCard/EditCard';
import { UserState } from '../../../store/reducers/user-reducer';

import './BroadcastForm.scss';

interface Props {
  onSubmit(data: any): void;
}

export const BroadcastForm: React.FC<Props> = (props) => {
  const {onSubmit} = props;
  const {loadTypes} = useBroadcastTestingTypesRequest();
  const {loadLotNumbers} = useBroadcastTestingLotsRequest();
  const {loadCodes} = useBroadcastTestingCodesRequest();
  const {loadDates} = useBroadcastTestingDatesRequest();
  const userState = useSelector<ApplicationState, UserState>(state => state.user);
  const broadcastState = useSelector<ApplicationState, BroadcastState>(state => state.broadcast);
  const {types, lots, codes, dates} = broadcastState;
  const {userData} = userState;
  const centerId = userData?.host?.id;

  const [formData, setFormData] = useState<TestingBroadcastFormEntity>(testingBroadcastFormEntityDefaults);
  const {type, identity_code, date, lot_no, message} = formData;

  useEffect(() => {
    if (centerId) {
      loadTypes(centerId);
    }
  }, [centerId]);

  useEffect(() => {
    if (type) {
      loadLotNumbers(centerId, type);

      setFormData({
        ...formData,
        date: '',
        lot_no: '',
        identity_code: '',
      })
    }
  }, [type]);

  useEffect(() => {
    if (lot_no) {
      loadCodes(centerId, type, lot_no);

      setFormData({
        ...formData,
        date: '',
        identity_code: '',
      })
    }
  }, [lot_no]);

  useEffect(() => {
    if (identity_code) {
      loadDates(centerId, type, lot_no, identity_code);

      setFormData({
        ...formData,
        date: '',
      })
    }
  }, [identity_code]);

  const handleFieldChange = (value: string | boolean | Date, fieldName: string) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = () => {
    onSubmit(formData);
  };
const checkValue = formData.type.length === 0 || formData.lot_no.length === 0 || formData.identity_code.length === 0
  || formData.date.length === 0 || formData.message.length === 0 ? true : false
  return (
    <Form className="broadcast-form" onSubmit={handleFormSubmit}>
      <EditCard
        leftContent={(
          <FormGroup title="Broadcast" fullWidth>
            <SelectMenu
              title="Test Type"
              value={type}
              name="type"
              valueKey="type"
              labelKey="type"
              options={types}
              onChange={handleFieldChange}
            />
            <SelectMenu
              title="Lot No."
              value={lot_no}
              name="lot_no"
              valueKey="lot_no"
              labelKey="lot_no"
              options={lots}
              placeholder="Lot No."
              onChange={handleFieldChange}
            />
            <SelectMenu
              title="Identity Code"
              value={identity_code}
              name="identity_code"
              valueKey="identity_code"
              labelKey="identity_code"
              options={codes}
              placeholder="Identity Code"
              onChange={handleFieldChange}
            />
            <SelectMenu
              title="Date Administered"
              placeholder="Date Administered"
              value={date}
              name="date"
              valueKey="date"
              labelKey="label"
              options={dates}
              onChange={handleFieldChange}
            />
          </FormGroup>
        )}
        rightContent={(
          <FormGroup fullWidth>
            <TextArea
              title="Message"
              placeholder="Message"
              value={message}
              onChange={handleFieldChange}
              name="message"
            />
          </FormGroup>
        )}
        footer={(
          <Button disabled={checkValue} title="Create" htmlType="submit" />
        )}
      />
    </Form>
  )
};
