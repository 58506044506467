import { CustomAction } from '../index';
import { TESTING_ACTION_TYPES } from '../actions-types';
import { CommonEntityRes } from '../../types/common';
import {
  AdministeredTest,
  RegisteredTest,
  TestingCenter,
  TestingDoctorCreateFormEntity,
  TestingUser,
} from '../../types/testing';
import { testingCenterDefault, testingDoctorCreateDefaults } from '../../data/defaults';

export interface TestingState {
  registered: CommonEntityRes<RegisteredTest>;
  administered: CommonEntityRes<AdministeredTest>;
  users: TestingUser[];
  center: TestingCenter;
  doctor: TestingDoctorCreateFormEntity;
  selectedTest: any;
}

const defaultState: TestingState = {
  registered: { data: [], page: 1 },
  administered: { data: [] },
  users: [],
  center: testingCenterDefault,
  doctor: testingDoctorCreateDefaults,
  selectedTest: undefined,
};

export default function testingReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case TESTING_ACTION_TYPES.SET_REGISTERED_ITEMS: {
      return {
        ...state,
        registered: action.data,
      };
    }
    case TESTING_ACTION_TYPES.SET_ADMINISTERED_ITEMS: {
      return {
        ...state,
        administered: action.data,
      };
    }
    case TESTING_ACTION_TYPES.SET_TESTING_USERS: {
      return {
        ...state,
        users: action.data,
      };
    }
    case TESTING_ACTION_TYPES.SET_TESTING_CENTER: {
      return {
        ...state,
        center: action.data,
      };
    }
    case TESTING_ACTION_TYPES.SET_TESTING_DOCTOR: {
      return {
        ...state,
        doctor: action.data,
      };
    }
    case TESTING_ACTION_TYPES.SET_SELECTED_TEST: {
      return {
        ...state,
        selectedTest: action.data,
      };
    }
    default:
      return state;
  }
}
