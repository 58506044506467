import React from 'react';
import './Logo.scss'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}
const Logo: React.FC<Props> = (props) => {
    const style: React.CSSProperties = {
        width: 100,
        ...props.style
    }
    return (
        <div {...props} className={`logo ${props.className}`} style={style}/>
    )
}

export default Logo;