export enum InputTypes {
  Password = 'password',
  Text = 'text',
}

export const DATE_FORMAT = 'YYYY-MM-DD';

export enum NotificationTypes {
  LFT_REVIEW = 'LFT_REVIEW',
  USER_REVIEW = 'USER_APPLICATION_REVIEW',
}
