import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NavMenuItem } from '../_common/NavMenuItem/NavMenuItem';
import { SVG_ICONS } from '../_common/Svg/Svg';
import { ROUTES } from '../../configs/routes';
import { URLS } from '../../constants/urls';
import { useUserPermissions } from '../../hooks/user-hooks';
import { AdminPermissionsNames } from '../../constants/users';
import { ApplicationState } from '../../store';
import { UserState } from '../../store/reducers/user-reducer';

import './Sidebar.scss';

export const Sidebar: React.FC = () => {
  const location = useLocation();
  const userState = useSelector<ApplicationState, UserState>(state => state.user);
  const {userData} = userState;
  const {hasPermissions} = useUserPermissions();
  const currentRoute = location.pathname;
  const showSidebar = !!userData.id;

  const isUserCanUseRegisteredTests = hasPermissions(AdminPermissionsNames.RegisteredTestTypes);
  const isUserCanUseAdministeredTests = hasPermissions(AdminPermissionsNames.AdministeredTestTypes);
  const isUserCanUseCentre = hasPermissions(AdminPermissionsNames.CentreDetails);
  const isUserCanUseBroadcast = hasPermissions(AdminPermissionsNames.BroadcastMessage);
  const isUserCanUseSubUserManagement = hasPermissions(AdminPermissionsNames.SubUserManagement);

  if (!showSidebar) return null;

  return (
    <div className="sidebar">
      <div className="sidebar__menu">
        <NavMenuItem
          route={ROUTES.REGISTERED_TYPES}
          icon={SVG_ICONS.SAFE}
          title="Registered Test Types"
          isActive={currentRoute.indexOf(URLS.REGISTERED_TYPES) !== -1}
          displayed={isUserCanUseRegisteredTests}
        />
        <NavMenuItem
          route={ROUTES.ADMINISTERED_TYPES}
          icon={SVG_ICONS.SHIELD}
          title="Administered Test Types"
          isActive={currentRoute.indexOf(URLS.ADMINISTERED_TYPES) !== -1}
          displayed={isUserCanUseAdministeredTests}
        />
        <NavMenuItem
          route={ROUTES.BROADCAST}
          icon={SVG_ICONS.COMMENT}
          title="Broadcast Message"
          isActive={currentRoute.indexOf(URLS.BROADCAST) !== -1}
          displayed={isUserCanUseBroadcast}
        />
      </div>
      <div className="sidebar__actions">
        <NavMenuItem
          route={ROUTES.CENTRE}
          icon={SVG_ICONS.INFO}
          title="Centre Details"
          isActive={currentRoute.indexOf(URLS.CENTRE) !== -1}
          displayed={isUserCanUseCentre}
        />
        <NavMenuItem
          route={ROUTES.USER_CREATE}
          icon={SVG_ICONS.USER}
          title="User Management"
          isActive={currentRoute.indexOf(ROUTES.USER_CREATE) !== -1}
          displayed={isUserCanUseSubUserManagement}
        />
      </div>
    </div>
  );
};
