export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const API_ENDPOINTS = {
  LOGIN: 'auth/signin',
  ME: 'auth/me',
  USERS: 'users',
  USER_QR: 'testing-users/{id}/sqrc',
  TESTING_TEST: 'tests',
  TESTING_CENTERS: 'testing-centres',
  TESTING_CENTER_REGISTERED: 'testing-centres/{mfp_id}/administered-tests',
  TESTING_CENTER_ADMINISTERED: 'testing-centres/{mfp_id}/registered-tests',
  TESTING_USERS: 'testing-users',
  TESTING_ADMINISTERED: 'tests/administered',
  TESTING_REGISTERED: 'tests/registered',
  TESTING_PHOTO: 'tests/test-photo',
  TESTING_USER_REGISTERED: 'testing-users/{mfp_id}/registered-tests',
  TESTING_USER_ADMINISTERED: 'testing-users/{mfp_id}/administered-tests',
  TESTING_DOCTOR: 'testing-centres/{id}/doctor',
  TESTING_DOCTOR_SIGNATURE: 'testing-centres/{id}/doctor/signature',
  TESTING_BROADCAST_CENTRES: 'broadcast/centres',
  TESTING_BROADCAST_TYPES: 'broadcast/centres/{centre_id}/test-types',
  TESTING_BROADCAST_LOTS: 'broadcast/centres/{centre_id}/test-types/{type}/lot-noms',
  TESTING_BROADCAST_CODES: 'broadcast/centres/{centre_id}/test-types/{type}/lot-noms/{lot_no}/identity-codes',
  TESTING_BROADCAST_DATES:
    'broadcast/centres/{centre_id}/test-types/{type}/lot-noms/{lot_no}/identity-codes/{identity_code}/administered-dates',

  // Notifications
  NOTIFICATIONS_LIST: 'notifications', // TODO: notif api
  NOTIFICATION_VIEW: 'notifications/{id}/view',
  NOTIFICATION_RECEIVER: 'notifications/register-reciever',

  // SCANNER
  SCANNER_QR: 'scanner/qr',
};
