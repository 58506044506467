import axios from 'axios';
import { USER_TOKEN_NAMESPACE } from '../constants/namespaces';
import { LocalStorage } from './storage';
import { CommonObject } from '../types/common';

export const Http = {
  getHeaders: async (extraHeaders?: CommonObject) => {
    const userToken = await LocalStorage.get(USER_TOKEN_NAMESPACE);
    const defaultHeaders = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    };

    return extraHeaders ? {Authorization: `Bearer ${userToken}`, ...extraHeaders} : defaultHeaders;
  },

  get: async (url: string, extraHeaders?: any) => {
    try {
      const headers = await Http.getHeaders(extraHeaders);
      const response = await fetch(url, {
        method: 'GET',
        headers,
      });

      // @ts-ignore
      if (headers['Content-Type'] === 'application/json') {
        const responseData = await response.json();

        if (responseData.error || responseData.statusCode) {
          return Promise.reject(responseData);
        }

        return responseData;
      }

      return response;
    } catch (e) {
      console.log(e);
    }
  },

  patch: async (url: string, body: any) => {
    try {
      const headers = await Http.getHeaders();
      const response = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers,
      });
      const responseData = await response.json();

      if (responseData.error || responseData.statusCode) {
        return Promise.reject(responseData);
      }

      return responseData;
    } catch (e) {
      console.log(e);
    }
  },

  post: async (url: string, body: any) => {
    try {
      const headers = await Http.getHeaders();
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers,
      });
      const responseData = await response.json();

      if (responseData.error || responseData.statusCode) {
        return Promise.reject(responseData);
      }

      return responseData;
    } catch (e) {
      console.log(e);
    }
  },

  postFile: async (url: string, body: any) => {
    try {
      const headers = await Http.getHeaders({
        'Content-Type': 'multipart/form-data',
      });
      const config = {headers};

      return axios.post(url, body, config);
    } catch (e) {
      console.log(e);
    }
  },

  put: async (url: string, body?: any) => {
    try {
      const headers = await Http.getHeaders();
      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers,
      });
      const responseData = await response.json();

      if (responseData.error) {
        return Promise.reject(responseData);
      }

      return responseData;
    } catch (e) {
      console.log(e);
    }
  },
};
