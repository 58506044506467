import React from 'react';
import { FormCard } from '../../../components/_common/FormCard/FormCard';
import { SignInForm } from '../../../components/authorization/SignInForm/SignInForm';
import { SignInEntity } from '../../../types/authorization';
import { useUserLogin } from '../../../hooks/user-hooks';

import './SignInPage.scss';

export const SignInPage = (): JSX.Element => {
  const { login } = useUserLogin();

  const handleFormSubmit = (data: SignInEntity) => {
    login(data);
  };

  return (
    <FormCard title="Welcome to Match Fit Pass">
      <div className="sign-in">
        <div className="sign-in__subtitle">Please log in with your email address or phone number</div>
        <SignInForm onSubmit={handleFormSubmit} />
        {/*<div className="sign-in__forget">*/}
        {/*  Forget your password? <Link to={ROUTES.FORGOT_PASSWORD}>Get some help</Link>*/}
        {/*</div>*/}
      </div>
    </FormCard>
  );
};
