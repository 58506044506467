import React from 'react';
import ReactModal from 'react-modal';

import './Modal.scss';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

ReactModal.setAppElement('#root');

export const Modal: React.FC<Props> = (props) => {
  const {children, isOpen, onClose} = props;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div>
        {children}
      </div>
    </ReactModal>
  );
};
