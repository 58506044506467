import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { API_BASE_URL, API_ENDPOINTS } from '../constants/api';
import { USER_SCANNER_QR_NAMESPACE } from '../constants/namespaces';
import { Http } from '../services/http';
import { LocalStorage } from '../services/storage';
import { ScannerQRRes } from '../types/scanner';
import { User } from '../types/user';

export const useScannerQR = () => {
  const [qr, setQR] = useState(null);
  const qrStore = LocalStorage.get(USER_SCANNER_QR_NAMESPACE);

  useEffect(() => {
    setQR(qrStore);
  }, [qrStore]);

  return { qr, setQR };
};

export const fetchScannerQR = async (user: User) => {
  const url = `${API_BASE_URL}/${API_ENDPOINTS.SCANNER_QR}`;
  const res: ScannerQRRes = await Http.get(url);
  if (user.host && res.api_key) {
    LocalStorage.set(USER_SCANNER_QR_NAMESPACE, `${user.host.id}${res.api_key}`);
  }
  return res;
};

export const useScannerQRFetch = (user: User) => {
  return useQuery('fetch-scanner-qr', async () => fetchScannerQR(user), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 3,
  });
};
