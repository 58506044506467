import { CustomAction } from '../index';
import { TESTING_ACTION_TYPES } from '../actions-types';
import { CommonEntityRes } from '../../types/common';
import { AdministeredTest, RegisteredTest, TestingCenter, TestingUser } from '../../types/testing';

export const actionSetRegisteredItems = (data: CommonEntityRes<RegisteredTest>): CustomAction => {
  return {
    type: TESTING_ACTION_TYPES.SET_REGISTERED_ITEMS,
    data,
  };
};

export const actionSetAdministeredItems = (data: CommonEntityRes<AdministeredTest>): CustomAction => {
  return {
    type: TESTING_ACTION_TYPES.SET_ADMINISTERED_ITEMS,
    data,
  };
};

export const actionSetTestingUsers = (data: TestingUser[]): CustomAction => {
  return {
    type: TESTING_ACTION_TYPES.SET_TESTING_USERS,
    data,
  };
};

export const actionSetTestingCenter = (data: TestingCenter): CustomAction => {
  return {
    type: TESTING_ACTION_TYPES.SET_TESTING_CENTER,
    data,
  };
};

export const actionSetTestingDoctor = (data: TestingCenter): CustomAction => {
  return {
    type: TESTING_ACTION_TYPES.SET_TESTING_DOCTOR,
    data,
  };
};

export const actionSetSelectedTest = (data: any): CustomAction => {
  return {
    type: TESTING_ACTION_TYPES.SET_SELECTED_TEST,
    data,
  };
};
