import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL, API_ENDPOINTS } from '../constants/api';
import { Http } from '../services/http';
import {
  actionSetBroadcastTestingCodes,
  actionSetBroadcastTestingDates,
  actionSetBroadcastTestingLots,
  actionSetBroadcastTestingTypes
} from '../store/actions/broadcast-actions';
import { notification } from '../services/notifications';
import { TestingBroadcastFormEntity } from '../types/broadcast';
import { ApplicationState } from '../store';
import { UserState } from '../store/reducers/user-reducer';
import { DATE_FORMAT } from '../constants/common';
import moment from 'moment';

export function useBroadcastTestingTypesRequest() {
  const dispatch = useDispatch();

  const loadTypes = async (centerId: string) => {
    try {
      const query = API_ENDPOINTS.TESTING_BROADCAST_TYPES.replace('{centre_id}', centerId);
      const url = `${API_BASE_URL}/${query}`;
      const res = await Http.get(url);

      dispatch(actionSetBroadcastTestingTypes(res));
    } catch (e) {
      console.log(e);
    }
  };

  return {loadTypes};
}

export function useBroadcastTestingLotsRequest() {
  const dispatch = useDispatch();

  const loadLotNumbers = async (centerId: string, type: string) => {
    try {
      const query = API_ENDPOINTS.TESTING_BROADCAST_LOTS
        .replace('{centre_id}', centerId)
        .replace('{type}', type);
      const url = `${API_BASE_URL}/${query}`;
      const res = await Http.get(url);

      dispatch(actionSetBroadcastTestingLots(res));
    } catch (e) {
      console.log(e);
    }
  };

  return {loadLotNumbers};
}

export function useBroadcastTestingCodesRequest() {
  const dispatch = useDispatch();

  const loadCodes = async (centerId: string, type: string, lot_no: string) => {
    try {
      const query = API_ENDPOINTS.TESTING_BROADCAST_CODES
        .replace('{centre_id}', centerId)
        .replace('{type}', type)
        .replace('{lot_no}', lot_no);
      const url = `${API_BASE_URL}/${query}`;
      const res = await Http.get(url);

      dispatch(actionSetBroadcastTestingCodes(res));
    } catch (e) {
      console.log(e);
    }
  };

  return {loadCodes};
}

export function useBroadcastTestingDatesRequest() {
  const dispatch = useDispatch();

  const loadDates = async (centerId: string, type: string, lot_no: string, code: string) => {
    try {
      const query = API_ENDPOINTS.TESTING_BROADCAST_DATES
        .replace('{centre_id}', centerId)
        .replace('{type}', type)
        .replace('{lot_no}', lot_no)
        .replace('{identity_code}', code);
      const url = `${API_BASE_URL}/${query}`;
      const res = await Http.get(url);
      const parsedDates = res.map((item: any) => {
        return {
          date: item.date,
          label: moment(item.date).format(DATE_FORMAT),
        }
      });

      dispatch(actionSetBroadcastTestingDates(parsedDates));
    } catch (e) {
      console.log(e);
    }
  };

  return {loadDates};
}

export function useBroadcastTestingMessageRequest() {
  const userState = useSelector<ApplicationState, UserState>(state => state.user);
  const {userData} = userState;
  const centerId = userData?.host?.id;

  const createMessage = async (data: TestingBroadcastFormEntity) => {
    try {
      const {identity_code, type, lot_no, date, message} = data;
      const requestData = {identity_code, date, lot_no, type, message};

      const url = `${API_BASE_URL}/${API_ENDPOINTS.TESTING_BROADCAST_CENTRES}/${centerId}`;
      await Http.post(url, requestData);

      notification.showSuccess('Message Is Created');

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  return {createMessage};
}