import React, { useState } from 'react';
import { FontSizeChangerBtn } from './FontSizeChangerBtn/FontSizeChangerBtn';
import './FontSizeChanger.scss';
import {useDispatch} from "react-redux";
import {actionSetFontSize} from "../../store/actions/user-actions";

export const FontSizeChanger: React.FC = () => {
  const [fontSize, setFontSize] = useState(16);
  const dispatch = useDispatch()
  const handleFontChange = (value: number) => {
    setFontSize(value);
    dispatch(actionSetFontSize(`${value}px`))
    document.body.style.fontSize = `${value}px`;
  };

  return (
    <div className="fs-changer">
      <FontSizeChangerBtn value={16} isActive={fontSize === 16} size={16} onClick={handleFontChange} />
      <FontSizeChangerBtn value={18} isActive={fontSize === 18} size={18} onClick={handleFontChange} />
      <FontSizeChangerBtn value={20} isActive={fontSize === 20} size={20} onClick={handleFontChange} />
    </div>
  );
};
