import { CustomAction } from '../index';
import { BROADCAST_ACTION_TYPES } from '../actions-types';
import { TestingDate, TestingIdentityCode, TestingLotNo, TestingType } from '../../types/broadcast';

export interface BroadcastState {
  types: TestingType[];
  lots: TestingLotNo[];
  codes: TestingIdentityCode[];
  dates: TestingDate[];
}

const defaultState: BroadcastState = {
  types: [],
  lots: [],
  codes: [],
  dates: [],
};

export default function broadcastReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_TYPES: {
      return {
        ...state,
        types: action.data,
      };
    }
    case BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_LOTS: {
      return {
        ...state,
        lots: action.data,
      };
    }
    case BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_CODES: {
      return {
        ...state,
        codes: action.data,
      };
    }
    case BROADCAST_ACTION_TYPES.SET_BROADCAST_TESTING_DATES: {
      return {
        ...state,
        dates: action.data,
      };
    }
    default:
      return state;
  }
}
