import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SignInEntity } from '../types/authorization';
import { API_BASE_URL, API_ENDPOINTS } from '../constants/api';
import { Http } from '../services/http';
import { USER_TOKEN_NAMESPACE } from '../constants/namespaces';
import { LocalStorage } from '../services/storage';
import { LoginResponse } from '../types/user';
import { ROUTES } from '../configs/routes';
import { actionSetLoginStatusUser, actionSetUser, actionSetUserQr } from '../store/actions/user-actions';
import { userDefaults } from '../data/defaults';
import { UtilsUser } from '../utils/UtilsUser';
import { ApplicationState } from '../store';
import { UserState } from '../store/reducers/user-reducer';
import { ADMIN_PERMISSIONS_ROUTES_MAP, AdminPermissionsNames, UserPermissionsLevels } from '../constants/users';
import { URLS } from '../constants/urls';
import { useInitializeFirebase } from './firebase-hooks';

export function useQRLoadingRequest() {
  const dispatch = useDispatch();

  const loadQr = async (userId: string) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.USER_QR.replace('{id}', userId)}`;
      const res = await Http.get(url, { 'Content-type': 'image/png' });
      const resBuffer = await res.blob();

      let imageUrl = URL.createObjectURL(resBuffer);
      const img: any = document.createElement('img');
      img.src = url;
      // in case you don't need the blob anymore
      img.onload = () => URL.revokeObjectURL(url);
      if (res?.status === 404) {
        imageUrl = '';
        alert('USER SQRC NOT FOUND');
      }
      dispatch(actionSetUserQr(imageUrl));
    } catch (e) {
      console.log(e);
    }
  };

  return { loadQr };
}

export function useUserDataRequest() {
  const dispatch = useDispatch();
  const { loadQr } = useQRLoadingRequest();

  const loadUser = async () => {
    try {
      if (UtilsUser.isUserTokenExists()) {
        const url = `${API_BASE_URL}/${API_ENDPOINTS.ME}`;
        const res = await Http.get(url);

        loadQr(res.mfp_id);

        dispatch(actionSetLoginStatusUser(true));
        dispatch(actionSetUser(res));
      }
    } catch (e) {
      console.log(e);
    }
  };

  return { loadUser };
}

export function useUserLogin() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { loadQr } = useQRLoadingRequest();

  const login = async (data: SignInEntity) => {
    try {
      const url = `${API_BASE_URL}/${API_ENDPOINTS.LOGIN}`;
      const res: LoginResponse = await Http.post(url, data);
      const { accessToken, user } = res;

      loadQr(user.mfp_id);

      await LocalStorage.set(USER_TOKEN_NAMESPACE, accessToken);

      dispatch(actionSetUser(user));
      useInitializeFirebase();

      dispatch(actionSetLoginStatusUser(true));

      push(ROUTES.REGISTERED_TYPES);
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  };

  const logout = async () => {
    try {
      await LocalStorage.set(USER_TOKEN_NAMESPACE, '');
      dispatch(actionSetUser(userDefaults));
      dispatch(actionSetLoginStatusUser(false));

      push(ROUTES.SIGN_IN);
    } catch (e) {
      console.log(e);
    }
  };

  return { login, logout };
}

export function useUserPermissions() {
  const userState = useSelector<ApplicationState, UserState>((state) => state.user);
  const { userData } = userState;
  const { id: userId, role, permissions } = userData || {};
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (userId && role.level === UserPermissionsLevels.Admin) {
      setIsAdmin(true);
    }
  }, [userId]);

  const hasPermissions = (permissionId: AdminPermissionsNames) => {
    if (permissions && permissions.length) {
      const permission = permissions.find((item) => item.name === permissionId);

      return permission ? permission.value : false;
    }

    return true;
  };

  return { isAdmin, hasPermissions };
}

export function usePermissionsRedirect() {
  const userState = useSelector<ApplicationState, UserState>((state) => state.user);
  const { userData } = userState;
  const { hasPermissions } = useUserPermissions();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const getAvailableRoute = () => {
    const firstAvailablePermission = userData.permissions.find((item) => item.value);

    if (firstAvailablePermission) {
      return ADMIN_PERMISSIONS_ROUTES_MAP[firstAvailablePermission.name];
    }

    return ROUTES.PLACEHOLDER;
  };

  const checkUserAccess = () => {
    const isUserCanUseRegisteredVaccines = hasPermissions(AdminPermissionsNames.RegisteredTestTypes);
    const isUserCanUseAdministeredVaccines = hasPermissions(AdminPermissionsNames.AdministeredTestTypes);
    const isUserCanUseCentre = hasPermissions(AdminPermissionsNames.CentreDetails);
    const isUserCanUseBroadcast = hasPermissions(AdminPermissionsNames.BroadcastMessage);
    const isUserCanUseSubUserManagement = hasPermissions(AdminPermissionsNames.SubUserManagement);

    if (~pathname.indexOf(URLS.REGISTERED_TYPES) && !isUserCanUseRegisteredVaccines) {
      return false;
    }

    if (~pathname.indexOf(URLS.ADMINISTERED_TYPES) && !isUserCanUseAdministeredVaccines) {
      return false;
    }

    if (~pathname.indexOf(URLS.CENTRE) && !isUserCanUseCentre) {
      return false;
    }

    if (~pathname.indexOf(URLS.USER) && !isUserCanUseSubUserManagement) {
      return false;
    }

    if (~pathname.indexOf(URLS.BROADCAST) && !isUserCanUseBroadcast) {
      return false;
    }

    return true;
  };
  const userHasAccess = checkUserAccess();

  useEffect(() => {
    if (!userHasAccess) {
      push(getAvailableRoute());
    }
  }, [userHasAccess, userData.id]);
}
