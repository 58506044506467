import { CustomAction } from '../index';
import { USER_ACTION_TYPES } from '../actions-types';
import { userDefaults } from '../../data/defaults';
import { User } from '../../types/user';

export interface UserState {
  userData: User;
  isUserLoggedIn: boolean;
  qr: string;
  fontSize: string | undefined;
  fcmInstance: any;
}

const defaultState: UserState = {
  userData: userDefaults,
  isUserLoggedIn: false,
  qr: '',
  fontSize: '',
  fcmInstance: null,
};

export default function userReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case USER_ACTION_TYPES.SET_USER: {
      return {
        ...state,
        userData: action.data,
      };
    }
    case USER_ACTION_TYPES.SET_USER_LOGIN_STATUS: {
      return {
        ...state,
        isUserLoggedIn: action.data,
      };
    }
    case USER_ACTION_TYPES.SET_USER_QR: {
      return {
        ...state,
        qr: action.data,
      };
    }
    case USER_ACTION_TYPES.SET_FONT_SIZE: {
      return {
        ...state,
        fontSize: action.data,
      };
    }
    case USER_ACTION_TYPES.SET_FCM_INSTANCE: {
      return {
        ...state,
        fcmInstance: action.data,
      };
    }
    default:
      return state;
  }
}
