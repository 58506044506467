import React, { useEffect, useState } from 'react';
import { Form } from '../../_common/Form/Form';
import { EditCard } from '../../_common/EditCard/EditCard';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { Checkbox } from '../../_common/_controls/Checkbox/Checkbox';
import { Button } from '../../_common/_controls/Button/Button';
import { SelectMenu } from '../../_common/_controls/SelectMenu/SelectMenu';
import { TestingUserFormEntity } from '../../../types/users';
import { testingFormEntityDefaults } from '../../../data/defaults';
import { useTestingCenterGetUserRequest } from '../../../hooks/users-hooks';
import { ApplicationState } from '../../../store';
import { TestingState } from '../../../store/reducers/testing-reducer';
import { useSelector } from 'react-redux';
import { UtilsUser } from '../../../utils/UtilsUser';

interface Props {
  onSubmit(data: any): void;
}

export const UserEditForm: React.FC<Props> = (props) => {
  const {onSubmit} = props;
  const {getUser} = useTestingCenterGetUserRequest();
  const testingState = useSelector<ApplicationState, TestingState>(state => state.testing);
  const [formData, setFormData] = useState<TestingUserFormEntity>(testingFormEntityDefaults);
  const {mfp_id, RegisteredTestTypes, AdministeredTestTypes, BroadcastMessage, SubUserManagement, CentreDetails} = formData;
  const {users} = testingState;

  const loadUser = async (id: string) => {
    const userData = await getUser(id);

    if (userData) {
      const permissions = userData.permissions;
      const convertedPermissions = UtilsUser.getUserPermissionsValues(permissions);

      setFormData({
        ...formData,
        ...convertedPermissions,
      })
    }
  };

  useEffect(() => {
    if (mfp_id) {
      loadUser(mfp_id);
    }
  }, [mfp_id]);

  const handleFieldChange = (value: string | boolean, name: string) => {
    setFormData({...formData, [name]: value});
  };

  const handleFormSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <EditCard
        leftContent={(
          <FormGroup fullWidth>
            <SelectMenu
              title="Active User"
              value={mfp_id}
              name="mfp_id"
              placeholder="Active User"
              labelKey="name"
              valueKey="id"
              options={users}
              onChange={handleFieldChange}
            />
          </FormGroup>
        )}
        rightContent={(
          <FormGroup fullWidth>
            <Checkbox
              checked={RegisteredTestTypes}
              name="RegisteredTestTypes"
              text="Registered Test Types"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={AdministeredTestTypes}
              name="AdministeredTestTypes"
              text="Administered Test Types"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={BroadcastMessage}
              name="BroadcastMessage"
              text="Broadcast Messages"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={SubUserManagement}
              name="SubUserManagement"
              text="Sub-User Management"
              onChange={handleFieldChange}
            />
            <Checkbox
              checked={CentreDetails}
              name="CentreDetails"
              text="Centre Details"
              onChange={handleFieldChange}
            />
          </FormGroup>
        )}
        footer={(
          <Button title="Update" htmlType="submit" />
        )}
      />
    </Form>
  );
};
