import { ROUTES } from '../configs/routes';

export enum AdminPermissionsNames {
  Users = 'Users',
  Hosts = 'Hosts',
  Invoice = 'Invoice',
  Audit = 'Audit Trail',
  Codes = 'User Codes',
  BroadcastMessage = 'Broadcast Message',
  Vaccination = 'Vaccination',
  Tickets = 'View Tickets',
  AccountDetails = 'Account Details',
  SubUserManagement = 'Sub-User Management',
  CentreDetails = 'Centre Details',
  AdministeredVaccines = 'Administered Vaccines',
  RegisteredVaccines = 'Registered Vaccines',
  RegisteredTestTypes = 'Registered Test Types',
  AdministeredTestTypes = 'Administered Test Types',
}

export const ADMIN_PERMISSIONS_MAP: any = {
  [AdminPermissionsNames.Users]: 'Users',
  [AdminPermissionsNames.Hosts]: 'Hosts',
  [AdminPermissionsNames.Invoice]: 'Invoice',
  [AdminPermissionsNames.Audit]: 'Audit',
  [AdminPermissionsNames.Codes]: 'Codes',
  [AdminPermissionsNames.BroadcastMessage]: 'BroadcastMessage',
  [AdminPermissionsNames.Vaccination]: 'Vaccination',
  [AdminPermissionsNames.Tickets]: 'Tickets',
  [AdminPermissionsNames.AccountDetails]: 'AccountDetails',
  [AdminPermissionsNames.SubUserManagement]: 'SubUserManagement',
  [AdminPermissionsNames.CentreDetails]: 'CentreDetails',
  [AdminPermissionsNames.RegisteredVaccines]: 'RegisteredVaccines',
  [AdminPermissionsNames.AdministeredVaccines]: 'AdministeredVaccines',
  [AdminPermissionsNames.RegisteredTestTypes]: 'RegisteredTestTypes',
  [AdminPermissionsNames.AdministeredTestTypes]: 'AdministeredTestTypes',
};

export const ADMIN_PERMISSIONS_ROUTES_MAP: any = {
  [AdminPermissionsNames.BroadcastMessage]: ROUTES.BROADCAST,
  [AdminPermissionsNames.SubUserManagement]: ROUTES.USER_CREATE,
  [AdminPermissionsNames.CentreDetails]: ROUTES.CENTRE,
  [AdminPermissionsNames.RegisteredTestTypes]: ROUTES.REGISTERED_TYPES,
  [AdminPermissionsNames.AdministeredTestTypes]: ROUTES.ADMINISTERED_TYPES,
};

export enum UserPermissionsLevels {
  Admin = 2,
}
