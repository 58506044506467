import { URLS } from '../constants/urls';

export const ROUTES = {
  ROOT: URLS.ROOT,
  SIGN_IN: URLS.SIGN_IN,
  REGISTERED_TYPES: URLS.REGISTERED_TYPES,
  ADMINISTERED_TYPES: URLS.ADMINISTERED_TYPES,
  BROADCAST: URLS.BROADCAST,
  CENTRE: URLS.CENTRE,
  PLACEHOLDER: URLS.PLACEHOLDER,
  USER_CREATE: `${URLS.USER}${URLS.CREATE}`,
  USER_EDIT: `${URLS.USER}${URLS.EDIT}`,
};
