export const URLS = {
  ROOT: '/',
  SIGN_IN: '/sign-in',
  REGISTERED_TYPES: '/registered-types',
  ADMINISTERED_TYPES: '/administered-types',
  BROADCAST: '/broadcast',
  CENTRE: '/centre',
  PLACEHOLDER: '/empty',
  USER: '/user',
  CREATE: '/create',
  EDIT: '/edit',
  // PARAMS: {
  //   ID: ':id',
  //   ID_OPTIONAL: ':id?',
  // },
};
