import React from 'react';
import classNames from 'classnames';

import './Checkbox.scss';

interface Props {
  checked: boolean;
  name?: string;
  minified?: boolean;
  disabled?: boolean;
  text: React.ReactNode;
  onChange?: (value: boolean, name: string) => void;
}

export const Checkbox: React.FC<Props> = (props) => {
  const {checked, text, name = '', minified, disabled, onChange} = props;
  const wrapperClass = classNames('checkbox', {
    'checkbox--minified': minified,
    'checkbox--disabled': disabled,
  });

  const handleChange = (e: React.ChangeEvent<any>) => {
    const input = e.target;

    if (onChange) {
      onChange(input.checked, name);
    }
  };

  return (
    <div className={wrapperClass}>
      <label className="checkbox__label">
        <input type="checkbox" checked={checked} onChange={handleChange} />
        <div className="checkbox__mark" />
        <div className="checkbox__text">{text}</div>
      </label>
    </div>
  );
};
