import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { store } from '../store';
import { actionSetFcmInstance } from '../store/actions/user-actions';
import { setNotificationReceiver } from './notification-hooks';

const {
  REACT_APP_VAPID_KEY,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
} = process.env;

const publicKey = REACT_APP_VAPID_KEY;
const firebaseConfig: FirebaseOptions = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const useInitializeFirebase = () => {
  console.log('[FCM] Initializing firebase');
  try {
    const firebaseApp: FirebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);
    console.log('[FCM] FCM Initialized');

    getFirebaseToken(messaging);
    store.dispatch(actionSetFcmInstance(messaging));
  } catch (e) {
    console.log('[FCM] Error initializing', e);
  }
};

export const getFirebaseToken = async (messaging: any) => {
  try {
    const token = await getToken(messaging, { vapidKey: publicKey });
    console.log('[FCM] token for this client', { token });
    const res = await setNotificationReceiver(token);
    if (res) {
      console.log('[FCM] Client registered', res);
    }
  } catch (e) {
    console.log('[FCM] Error', e);
  }
};

export const getMessageListener = async (messaging: any) => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};
