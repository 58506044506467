import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { Table } from '../../../components/_common/Table/Table';
import { VaccinesHeader } from '../../../components/vaccines/VaccinesHeader/VaccinesHeader';
import { useRegisteredExport, useRegisteredItemsRequest } from '../../../hooks/tests-hooks';
import { ApplicationState } from '../../../store';
import { TestingState } from '../../../store/reducers/testing-reducer';
import { CommonObject } from '../../../types/common';
import { DATE_FORMAT } from '../../../constants/common';
import moment from 'moment';
import { ResultSelectionWindow } from '../../../components/testing/ResultSelectionWindow/ResultSelectionWindow';
import { Svg, SVG_ICONS } from '../../../components/_common/Svg/Svg';
import { actionSetSelectedTest } from '../../../store/actions/testing-actions';

export const RegisteredTypesPage = (): JSX.Element => {
  const testingState = useSelector<ApplicationState, TestingState>((state) => state.testing);
  const dispatch = useDispatch();
  const { loadItems } = useRegisteredItemsRequest();
  const { exportItemsToCsv } = useRegisteredExport();
  const [searchParams, setSearchParams] = useState({ search: '' });
  const { search } = searchParams;
  const { registered, selectedTest } = testingState;
  const { page = 1, total = 0, limit, data } = registered;
  const [dateRange, setDateRange] = useState({
    date_start: null,
    date_end: null,
  });

  useEffect(() => {
    loadItems(1, searchParams, { ...dateRange });
  }, []);

  useEffect(() => {
    handleSearch(search);
  }, [dateRange]);

  const registeredColumns = React.useMemo(
    () => [
      {
        Header: 'Unit Location',
        accessor: 'location',
      },
      {
        Header: 'Test Type',
        accessor: 'type',
      },
      {
        Header: 'Lot No.',
        accessor: 'lot_no',
      },
      {
        Header: 'Passenger Locator No.',
        accessor: 'pln',
      },
      {
        Header: 'Identity Code',
        accessor: 'identity_code',
      },
      {
        Header: 'Date Tested',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const { date_tested } = original;

          return moment(date_tested).format(DATE_FORMAT);
        },
      },
      {
        Header: 'MFP ID',
        accessor: 'mfp_id',
      },
      {
        Header: 'Result',
        Cell: (data: any) => {
          const { row } = data;
          const { original } = row;
          const handleClick = () => {
            dispatch(actionSetSelectedTest(original));
          };

          return (
            <button className="btn-empty" type="button" onClick={handleClick}>
              <Svg icon={SVG_ICONS.CHEVRON_RIGHT} />
            </button>
          );
        },
      },
    ],
    []
  );

  const searchForUsers = useCallback(
    debounce(async (params: CommonObject, filter: any) => {
      loadItems(1, params, filter);
    }, 400),
    []
  );

  const handleSearch = (searchValue: string) => {
    const newParams = { ...searchParams, search: searchValue };
    setSearchParams(newParams);
    searchForUsers(newParams, { ...dateRange });
  };

  const handleExport = () => {
    exportItemsToCsv(searchParams.search, total);
  };

  const handleGoToNextPage = async () => {
    const nextPage = page + 1;

    await loadItems(nextPage, searchParams, { ...dateRange });
  };

  const handleGoToPrevPage = async () => {
    const prevPage = page - 1;

    await loadItems(prevPage, searchParams, { ...dateRange });
  };

  const handleResultWindowClose = () => {
    dispatch(actionSetSelectedTest(undefined));
  };

  const handleTestUpdate = () => {
    loadItems(page, searchParams, { ...dateRange });
  };

  return (
    <>
      <div>
        <VaccinesHeader
          title="Registered Test Types"
          searchValue={search}
          onSearch={handleSearch}
          onExport={handleExport}
          setDateRange={setDateRange}
        />
        <div className="page-content">
          <Table
            data={data}
            columns={registeredColumns}
            page={page}
            limit={limit}
            total={total}
            onNext={handleGoToNextPage}
            onPrev={handleGoToPrevPage}
          />
        </div>
      </div>
      <ResultSelectionWindow data={selectedTest} onClose={handleResultWindowClose} onUpdate={handleTestUpdate} />
    </>
  );
};
